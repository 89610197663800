import React, { useEffect, useRef, useState } from "react";
import consolidateSvg from "../../assets/icons/consolidate.svg";
import smsSvg from "../../assets/icons/communication.svg";
import formSvg from "../../assets/icons/form.svg";
import timeIsMoneySvg from "../../assets/icons/time-is-money.svg";
import portalSvg from "../../assets/icons/portal.svg";
import xRaySvg from "../../assets/icons/x-ray.svg";
import bookingSvg from "../../assets/icons/booking.svg";
import destinationSvg from "../../assets/icons/destinations.svg";
import telephoneSvg from "../../assets/icons/telephone.svg";

const FeatureList: React.FC = () => {
  const features = [
    {
      imgSrc: consolidateSvg,
      bgColour: "bg-brand-blue",
      title: "Consolidate Referrals",
      description:
        "Consolidate referrals from all sources, including website, phone, and E-Referrals, into one manageable list.",
    },
    {
      imgSrc: smsSvg,
      bgColour: "bg-brand-orange",
      title: "Instant SMS for Referrals",
      description:
        "Retain patients instantly with SMS, preventing them from shopping around.",
    },
    {
      imgSrc: formSvg,
      bgColour: "bg-brand-pink-dark",
      title: "Digitized Patient Forms",
      description:
        "Effortlessly digitize forms like patient registration for a smoother experience.",
    },
    {
      imgSrc: timeIsMoneySvg,
      bgColour: "bg-brand-blue",
      title: "No-Show Protection",
      description:
        "Stop losing money to patient no shows by using our configurable holding payment system.",
    },
    {
      imgSrc: portalSvg,
      bgColour: "bg-brand-orange",
      title: "Patient File Portal",
      description:
        "Easily and securely share sensitive files and important information with your patients.",
    },
    {
      imgSrc: xRaySvg,
      bgColour: "bg-brand-pink-dark",
      title: "Smart Procedure Selection",
      description:
        "Reduce costs and save time by using our smart procedure selector to ensure the right procedure every time.",
    },
    {
      imgSrc: bookingSvg,
      bgColour: "bg-brand-blue",
      title: "Smart Booking Assistant",
      description:
        "Online booking assist guides patients through booking, presents available procedure times, and allows final confirmation by a human.",
    },
    {
      imgSrc: destinationSvg,
      bgColour: "bg-brand-orange",
      title: "Multi-Location Support",
      description: "All our products support multi-location.",
    },
    {
      imgSrc: telephoneSvg,
      bgColour: "bg-brand-pink-dark",
      title: "Phone referrals assist",
      description: "Receive patient referrals in real-time while on the phone.",
    },
  ];

  const FeatureCard = ({
    imgSrc,
    bgColour,
    title,
    description,
  }: (typeof features)[0]) => (
    <div className="bg-gray-50 hover:border-2 border border-gray-300 hover:border-black rounded-lg p-6 flex flex-col justify-between min-h-[230px] w-full">
      <div>
        <div className={`${bgColour} w-16 mb-4 rounded-full`}>
          <img src={imgSrc} className="p-4" alt={title} />
        </div>
        <p className="text-lg font-bold pb-2">{title}</p>
        <p className="text-gray-600 text-sm">{description}</p>
      </div>
    </div>
  );

  // const [currentIndex, setCurrentIndex] = useState(0);
  const [cardWidth, setCardWidth] = useState(400);
  const containerRef = useRef<HTMLDivElement>(null);
  const handleResize = () => {
    const width = Math.min(window.innerWidth - 32, 400); // Dynamic card width
    setCardWidth(width);
  };

  useEffect(() => {
    handleResize(); // Set initial card width
    window.addEventListener("resize", handleResize); // Listen for window resizing
    return () => window.removeEventListener("resize", handleResize);
  }, []);



  const handleMoveLeft = () => {
    containerRef.current?.scrollBy({ left: -cardWidth - 16, behavior: "smooth" });
  };

  const handleMoveRight = () => {
    containerRef.current?.scrollBy({ left: cardWidth + 16, behavior: "smooth" });
  };

  // const translateValue = `translateX(-${currentIndex * (cardWidth + 16)}px)`;

  return (
    <div className="relative container mx-auto 2xl:max-w-6xl px-2">
      {/* Card container */}
      <div ref={containerRef} className="overflow-auto  flex">
        {features.map((feature, index) => (
          <div
            key={index}
            className="flex-shrink-0"
            style={{ width: `${cardWidth}px`, marginRight: "16px" }}
          >
            <FeatureCard {...feature} />
          </div>
        ))}
      </div>

      {/* Navigation buttons */}
      <div className="flex justify-end pt-2">
        <button
          className="bg-gray-200 p-2 rounded-full mr-1"
          onClick={handleMoveLeft}
        >
          <svg
            className="w-6 h-6 text-gray-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            ></path>
          </svg>
        </button>

        <button
          className="bg-gray-200 p-2 rounded-full"
          onClick={handleMoveRight}
        >
          <svg
            className="w-6 h-6 text-gray-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default FeatureList;
