import React from "react";
import patientIpadImg from "../assets/images/landing_page/patient-doctor-ipad.png";
import patientJourney from "../assets/images/landing_page/patient_journey_black.svg";
import patientJourneyMobile from "../assets/images/landing_page/patient_journey_mobile.png";
import radiologyImg from "../assets/images/landing_page/radiology_image.png";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import FeatureList from "./landing_page/FeatureList";
import StepIntoTheFuture from "./landing_page/StepIntoTheFuture";

declare global {
  interface Window {
    gtag_report_conversion: (url: string) => void;
  }
}

const LandingPage: React.FC = () => {
  const typeFormRegistrationForm =
    "https://8mt2mee3ezj.typeform.com/to/N0dg9emL";
  const typeFormContactUsForm = "https://8mt2mee3ezj.typeform.com/to/eR2meZA5";

  const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return (
      <button
        className=" text-sm block py-2 px-4 uppercase font-medium hover:underline"
        onClick={() => loginWithRedirect()}
      >
        Log In
      </button>
    );
  };

  const trackConversion = (url: string) => {
    if (process.env.REACT_APP_ENV !== "production") {
      return;
    }

    if (window.gtag_report_conversion) {
      window.gtag_report_conversion(url);
      console.log(`Click through tracked: ${url}`);
    } else {
      console.error("gtag_report_conversion function is not available");
    }
  };

  const requestDemo = () => {
    window.open(typeFormRegistrationForm, "_blank");
    trackConversion(typeFormRegistrationForm);
  };

  return (
    <body className="antialiased bg-body text-body font-body">
      <div className="">
        <section className="relative overflow-hidden bg-brand-blue">
          <section className="flex justify-center pb-10">
            <div className="container px-2 z-10">
              <div className="flex items-center justify-between pt-3">
                <h1 className="text-3xl block uppercase font-bold">ELVATI</h1>
                <div className="flex">
                  <LoginButton />
                  <button
                    className="bg-black text-white text-sm sm:text-base 2xl:text-base md:text-base py-2 px-5 uppercase font-light font-medium hover:bg-gray-700 rounded"
                    onClick={() => window.open(typeFormContactUsForm, "_blank")}
                  >
                    Contact Us
                  </button>
                </div>
              </div>
            </div>
          </section>

          <div className="relative mx-auto max-w-5xl px-2 pt-8 pb-10">
            <div className="flex flex-col">
              <p
                className="text-4xl px-4 pb-4 text-center font-serif sm:text-7xl 2xl:text-7xl"
                style={{ lineHeight: "1.2" }}
              >
                We automate patient journeys so you can focus on patient care.
              </p>
              <div className="pb-4 text-center">
                <button
                  className="bg-black text-sm sm:text-base 2xl:text-base text-white mt-4 py-2 px-6 font-light uppercase font-medium hover:bg-gray-700 rounded uppercase outline outline-1 outline-black outline-offset-[5px]"
                  onClick={() => requestDemo()}
                >
                  Book A Demo
                </button>
              </div>
            </div>
          </div>
          <img src={patientJourney} alt="" className="w-full pb-8 hidden sm:block" />
          <img src={patientJourneyMobile} alt="" className="w-full pr-8 pl-12 sm:hidden" />
        </section>

        <section className="">
          <div className="relative container mx-auto 2xl:max-w-6xl px-2 pb-6">
            <div className="inline-block mb-3 bg-gray-400 text-sm text-white py-1 px-4 rounded-full mt-8">
              <p className="uppercase">Product suite</p>
            </div>
            <p className="text-3xl mb-1 font-serif sm:text-6xl 2xl:text-6xl">
              Reduce administrative burden
            </p>
            <p className="text-3xl pb-10 text-gray-600 font-serif sm:text-6xl 2xl:text-6xl">
              across your entire patient journey
            </p>
          </div>

          <FeatureList />

          <div className="relative container mx-auto 2xl:max-w-6xl px-2 pt-6">
            <div className="grid grid-cols-1 md:grid-cols-12 pb-8">
              <div className="md:col-span-6 my-3 sm:my-8 2xl:my-8"></div>
              <div className="md:col-span-6 flex flex-col justify-center items-center p-6">
                <div className="max-w-lg">
                  <p className="text-4xl pb-6 font-serif sm:text-5xl 2xl:text-5xl">
                    Have a special requirement?
                  </p>
                  <p className="text-gray-800 pb-6">
                    We partner with our clients to identify the most time-consuming aspects of their patient journeys and deliver tailored solutions to streamline and automate processes.
                  </p>
                  <button
                    className="inline-block bg-brand-brown-dark text-white text-base py-3 px-6 font-medium rounded-lg hover:ring-4 cursor-pointer"
                    onClick={() => requestDemo()}
                  >
                    Contact Us
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-brand-orange">
          <div className="relative container mx-auto 2xl:max-w-6xl px-2">
            <div className="grid grid-cols-1 md:grid-cols-12">
              <div className="md:col-span-6 my-8">
                <img src={radiologyImg} alt="" className="w-full rounded-lg" />
              </div>
              <div className="md:col-span-6 flex flex-col justify-center items-center p-3 pb-8 sm:p-5 2xl:p-6">
                <div className="max-w-lg">
                  <div className="inline-block mb-4 bg-gray-400 text-white text-sm  py-1 px-4 rounded-full mt-0 sm:mt-8 2xl:mt-8">
                    <p className="uppercase">Our mission</p>
                  </div>
                  <p className=" text-4xl pb-6 font-serif sm:text-5xl 2xl:text-5xl">
                    A Paradigm Shift In Patient Management
                  </p>
                  <p className=" pb-6">
                    We provide a solution for Healthcare Clinics to automate
                    their patient workflows, streamlining and automating administrative tasks. By handling the time-consuming
                    aspects of managing patients, our system allows healthcare
                    professionals to focus more on patient care.
                  </p>
                  <button
                    className="inline-block bg-brand-brown-dark text-white text-base py-4 px-7 font-medium rounded-lg hover:ring-4 cursor-pointer"
                    onClick={() => requestDemo()}
                  >
                    Book A Demo
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <StepIntoTheFuture />

        <section className="bg-brand-blue">
          <div className="relative container mx-auto py-7">
            <div className="flex justify-center">
              <div className="sm:max-w-6xl px-2">
                <div className="flex flex-col md:flex-row justify-between md:items-start">
                  <div className="md:text-left md:mb-0">
                    <p className="font-serif text-black text-5xl md:text-9xl font-semibold pb-8 ">
                      Try Elvati Risk Free
                    </p>
                  </div>

                  <div className="md:text-right pb-6">
                    <button
                      className="inline-block bg-brand-brown-dark text-white text-base py-4 px-7 font-medium rounded-lg hover:ring-4"
                      onClick={() => requestDemo()}
                    >
                      Book A Demo
                    </button>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-12 pb-10 gap-5  pb-8 rounded-lg">
                  <div
                    className="relative md:col-span-6 pb-8 border border-blue-900 rounded-lg p-6 bg-brandBlue-500 bg-cover bg-center"
                    style={{
                      backgroundImage: `linear-gradient(rgba(77, 77, 77, 0.8), rgba(9, 9, 9, 0.6)), url(${patientIpadImg})`,
                    }}
                  >
                    <div className="relative z-10">
                      <p className="font-serif text-5xl text-white font-semibold pb-4">
                        Start using it today!
                      </p>
                      <p className="text-white">
                        Start using our innovative solutions and experience the
                        efficiency and ease of automated patient journeys.
                      </p>
                    </div>
                  </div>

                  <div className="md:col-span-3 pb-8 rounded-lg p-6 bg-brand-blue-dark">
                    <p className="text-white pb-8 uppercase">Risk free</p>
                    <p className="font-serif text-8xl text-white  font-semibold pb-2">
                      60 Day
                    </p>

                    <p className="text-white">money back guarantee</p>
                  </div>
                  <div className="md:col-span-3 pb-8 rounded-lg p-6 bg-brand-blue-dark">
                    <p className="text-white pb-8 uppercase">Quick Setup</p>
                    <p className="font-serif text-8xl text-white  font-semibold pb-2">
                      24 hours
                    </p>

                    <p className="text-white">to start using Elvati</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="overflow-hidden">
          <div className="container mx-auto">
            <p className="text-center py-8 text-sm">
              © Copyright {moment().year()} - All Rights Reserved by Elvati PTY
              LTD - ACN: 675 878 270
            </p>
          </div>
        </section>
      </div>
    </body>
  );
};

export default LandingPage;
