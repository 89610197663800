import React, { useState } from "react";

import intelligentWorkflows from "../../assets/images/landing_page/intelligent_workflows.svg";
import referralScanner from "../../assets/images/landing_page/referral_scanner.svg";
import aiAssist from "../../assets/images/landing_page/ai_assist.svg";

const StepIntoTheFuture: React.FC = () => {
  const [activeSection, setActiveSection] = useState<string>(
    "Intelligent Workflows",
  );

  return (
    <section className="pb-12 mt-6">
      <div className="relative container mx-auto 2xl:max-w-6xl px-4 flex flex-col items-center">
        <div className="inline-block bg-gray-400 text-sm text-white py-1 px-4 rounded-full mt-8 ">
          <p className="uppercase">Our solution</p>
        </div>
        <p className="text-4xl sm:text-5xl 2xl:text-6xl font-serif pb-6 pt-3 text-center">
          Step into the future of healthcare
        </p>

        {/* Navigation Tabs */}
        <div className="flex pt-4 pb-6">
          {["Intelligent Workflows", "A.I Assist", "Patient Engagement"].map(
            (tab) => (
              <p
                key={tab}
                className={`pb-2 text-lg font-bold cursor-pointer mx-3 ${
                  activeSection === tab ? "border-b-2 border-black" : ""
                }`}
                onClick={() => setActiveSection(tab)}
              >
                {tab}
              </p>
            ),
          )}
        </div>

        {/* Content Sections */}
        <div className="flex">
          {activeSection === "Intelligent Workflows" && (
            <div className="flex flex-col md:flex-row justify-between items-center p-6 border border-black rounded-xl">
              <div className="w-full md:w-1/2 flex justify-center items-center px-10 mb-6 md:mb-0">
                <img src={intelligentWorkflows} alt="Intelligent Workflows" />
              </div>

              {/* Right Side - Text Content */}
              <div className="w-full md:w-1/2 pl-0 md:pl-4 text-center md:text-left">
                <p className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-serif pb-4">
                  Intelligent workflows
                </p>

                <p className="text-gray-800 pb-8 max-w-prose mx-auto md:mx-0">
                  Automate and optimize the end-to-end process, including
                  referral intake, triage, payment, and scheduling.
                </p>

                <div className="flex text-xs flex-wrap justify-center md:justify-start">
                  <div className="mb-3 bg-gray-100 py-2 px-4 rounded-full mr-2">
                    <p className="uppercase">Workflow automation</p>
                  </div>
                  <div className="mb-3 bg-gray-100 py-2 px-4 rounded-full mr-2">
                    <p className="uppercase">Integrate with current systems</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeSection === "A.I Assist" && (
            <div className="flex flex-col md:flex-row justify-between items-center p-6 border border-black rounded-xl">
              {/* Left Side - Image */}
              <div className="w-full md:w-1/2 flex justify-center items-center px-10 mb-6 md:mb-0">
                <img src={aiAssist} alt="Intelligent Workflows" />
              </div>

              {/* Right Side - Text Content */}
              <div className="w-full md:w-1/2 pl-0 md:pl-4 text-center md:text-left">
                <p className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-serif pb-4">
                  A.I Assist
                </p>

                <p className="text-gray-800 pb-8 max-w-prose mx-auto md:mx-0">
                  Use data analytics and AI to enhance decision-making at key
                  touchpoints.
                </p>

                <div className="flex text-xs flex-wrap justify-center md:justify-start">
                  <div className="mb-3 bg-gray-100 py-2 px-4 rounded-full mr-2">
                    <p className="uppercase">A.I Automated Decisions</p>
                  </div>
                  <div className="mb-3 bg-gray-100 py-2 px-4 rounded-full mr-2">
                    <p className="uppercase">A.I Suggestions</p>
                  </div>
                  <div className="mb-3 bg-gray-100 py-2 px-4 rounded-full mr-2">
                    <p className="uppercase">
                      Continuous learning for refinement
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeSection === "Patient Engagement" && (
            <div className="flex flex-col md:flex-row justify-between items-center p-6 border border-black rounded-xl">
              {/* Left Side - Image */}
              <div className="w-full md:w-1/2 flex justify-center items-center px-10 mb-6 md:mb-0">
                <img
                  src={referralScanner}
                  alt="Intelligent Workflows"
                  style={{ maxHeight: "300px" }}
                />
              </div>

              {/* Right Side - Text Content */}
              <div className="w-full md:w-1/2 pl-0 md:pl-4 text-center md:text-left">
                <p className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-serif pb-4">
                  Patient Engagement
                </p>

                <p className="text-gray-800 pb-8 max-w-prose mx-auto md:mx-0">
                  Prioritize patient engagement and personalization throughout
                  the journey to ensure high satisfaction.
                </p>

                <div className="flex text-xs flex-wrap justify-center md:justify-start">
                  <div className="mb-3 bg-gray-100 py-2 px-4 rounded-full mr-2">
                    <p className="uppercase">Self service tools</p>
                  </div>
                  <div className="mb-3 bg-gray-100 py-2 px-4 rounded-full mr-2">
                    <p className="uppercase">Automated messages</p>
                  </div>
                  <div className="mb-3 bg-gray-100 py-2 px-4 rounded-full mr-2">
                    <p className="uppercase">Transparent updates</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default StepIntoTheFuture;
